<template src="./template.html"></template>

<script>
import Http from '@/shared/http-config'
import CmsStore from '../CmsStore'
import { mapFields } from 'vuex-map-fields';
import RegisterStoreModule from '@/shared/registerStoreModule';
import moment from 'moment';

export default {
  name: 'Template',
  mixins: [RegisterStoreModule],
  data: function () {
    return {
      user: {},
    }
  },
  computed: {
    ...mapFields('cms', [
      'template',
      'templates',
    ]),
  },
  created: function(){
    this.user = this.$store.getters.getUser;
    this.registerStoreModule('cms', CmsStore);
    this.start();
  },
  watch: {
    $route(to, from) {
      this.start();
    }
  },
  methods: {
    start (){
      let self = this;
      Http.request('GET', '/website/template')
        .then((templates) => {
          self.templates = templates.data;

          if(self.$route.params.template > 0){
            self.template = self.templates.filter(item => parseInt(item.id) === parseInt(self.$route.params.template))[0];
          }
          else if(self.templates.length > 0){
            self.template = self.templates.filter(item => item.selected === true)[0];
            if(!self.template){
              self.template = self.templates[0];
            }
            self.$router.replace('/website/cms/' + self.template.id + '/0');
          }
        });
    }
  },
}
</script>

<style scoped></style>
<style lang="scss"></style>
