import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,

  state () {
    return {
      current_nav: {},
      headers: [],
      navigation: [],
      template: null,
      language: null,
      templates: [],
      languages: [],
      modules: [],
      forms: [],
    }
  },

  actions: {
    addHeader(context, data){
      context.commit('ADD_HEADER', data);
    },
    deleteHeader(context, key){
      context.commit('DELETE_HEADER', key);
    },
    replaceHeader(context, data){
      context.commit('REPLACE_HEADER', data);
    },
  },

  mutations: {
    updateField,
    ADD_HEADER(state, value) {
      state.headers.push(value);
    },
    DELETE_HEADER(state, key) {
      state.headers.splice(key, 1);
    },
    REPLACE_HEADER(state, header) {
      Vue.set(state.headers, state.headers.findIndex(f => f.id === header.id), header)
    },
  },

  getters: {
    getField,
  }
};
